import colors from "colors";
import { IconProps } from "./types";

export default function IcoAddUser({
	color = colors.neutral["low-pure-500"],
	size = "24"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.1109 13.1109C14.1424 12.0795 15.5413 11.5 17 11.5C18.4587 11.5 19.8576 12.0795 20.8891 13.1109C21.9205 14.1424 22.5 15.5413 22.5 17C22.5 18.4587 21.9205 19.8576 20.8891 20.8891C19.8576 21.9205 18.4587 22.5 17 22.5C15.5413 22.5 14.1424 21.9205 13.1109 20.8891C12.0795 19.8576 11.5 18.4587 11.5 17C11.5 15.5413 12.0795 14.1424 13.1109 13.1109ZM17 12.5C15.8065 12.5 14.6619 12.9741 13.818 13.818C12.9741 14.6619 12.5 15.8065 12.5 17C12.5 18.1935 12.9741 19.3381 13.818 20.182C14.6619 21.0259 15.8065 21.5 17 21.5C18.1935 21.5 19.3381 21.0259 20.182 20.182C21.0259 19.3381 21.5 18.1935 21.5 17C21.5 15.8065 21.0259 14.6619 20.182 13.818C19.3381 12.9741 18.1935 12.5 17 12.5Z"
				fill={color}
			/>
			<path
				d="M17.5 14.5C17.5 14.2239 17.2761 14 17 14C16.7239 14 16.5 14.2239 16.5 14.5V16.5H14.5C14.2239 16.5 14 16.7239 14 17C14 17.2761 14.2239 17.5 14.5 17.5H16.5V19.5C16.5 19.7761 16.7239 20 17 20C17.2761 20 17.5 19.7761 17.5 19.5V17.5H19.5C19.7761 17.5 20 17.2761 20 17C20 16.7239 19.7761 16.5 19.5 16.5H17.5V14.5Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.18967 1.84254C8.73563 1.6164 9.3208 1.5 9.91174 1.5C10.5027 1.5 11.0879 1.6164 11.6338 1.84254C12.1798 2.06869 12.6759 2.40016 13.0937 2.81802C13.5116 3.23588 13.8431 3.73196 14.0692 4.27792C14.2953 4.82389 14.4117 5.40905 14.4117 6C14.4117 6.59095 14.2953 7.17611 14.0692 7.72208C13.8431 8.26804 13.5116 8.76412 13.0937 9.18198C12.6759 9.59984 12.1798 9.93131 11.6338 10.1575C11.0879 10.3836 10.5027 10.5 9.91174 10.5C9.32079 10.5 8.73563 10.3836 8.18967 10.1575C7.6437 9.93131 7.14763 9.59984 6.72976 9.18198C6.3119 8.76412 5.98043 8.26804 5.75429 7.72208C5.52814 7.17611 5.41174 6.59095 5.41174 6C5.41174 5.40905 5.52814 4.82389 5.75429 4.27792C5.98043 3.73196 6.3119 3.23588 6.72976 2.81802C7.14763 2.40016 7.6437 2.06869 8.18967 1.84254ZM9.91174 2.5C9.45212 2.5 8.99699 2.59053 8.57235 2.76642C8.14771 2.94231 7.76187 3.20012 7.43687 3.52513C7.11186 3.85013 6.85406 4.23597 6.67816 4.66061C6.50227 5.08525 6.41174 5.54037 6.41174 6C6.41174 6.45963 6.50227 6.91475 6.67816 7.33939C6.85406 7.76403 7.11186 8.14987 7.43687 8.47487C7.76187 8.79988 8.14771 9.05769 8.57235 9.23358C8.99699 9.40947 9.45212 9.5 9.91174 9.5C10.3714 9.5 10.8265 9.40947 11.2511 9.23358C11.6758 9.05769 12.0616 8.79988 12.3866 8.47487C12.7116 8.14987 12.9694 7.76403 13.1453 7.33939C13.3212 6.91475 13.4117 6.45963 13.4117 6C13.4117 5.54037 13.3212 5.08525 13.1453 4.66061C12.9694 4.23597 12.7116 3.85013 12.3866 3.52513C12.0616 3.20012 11.6758 2.94231 11.2511 2.76642C10.8265 2.59053 10.3714 2.5 9.91174 2.5Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 12.5C8.01088 12.5 6.10322 13.2902 4.6967 14.6967C3.40849 15.9849 2.6373 17.6935 2.51667 19.5H10C10.2761 19.5 10.5 19.7239 10.5 20C10.5 20.2761 10.2761 20.5 10 20.5H2C1.86739 20.5 1.74021 20.4473 1.64645 20.3536C1.55268 20.2598 1.5 20.1326 1.5 20C1.5 17.7457 2.39553 15.5837 3.98959 13.9896C5.58365 12.3955 7.74566 11.5 10 11.5C10.4943 11.5 10.9843 11.5431 11.4647 11.6271C11.7367 11.6747 11.9187 11.9338 11.8711 12.2058C11.8235 12.4778 11.5644 12.6597 11.2924 12.6122C10.8685 12.538 10.4362 12.5 10 12.5Z"
				fill={color}
			/>
		</svg>
	);
}
