import { IcoArrowLeft } from "assets/icons";
import colors from "colors";
import { Button } from "components";
import "./styles.scss";

type InfoProps = {
	label: string;
	value?: string | React.ReactNode;
	alignClass?: "start-align" | "center-align";
	className?: string;
};

type DetailsProps = {
	title: string;
	infos: Array<InfoProps>;
};

type PdfViewerDetailsContainerProps = {
	onClickReturn: () => void;
	actionButtons: React.ReactNode;
	pdfUrl?: string | null;
	detailsTitle: string;
	infosDetails: Array<DetailsProps>;
};

export default function PdfViewerDetailsContainer({
	onClickReturn,
	actionButtons,
	pdfUrl,
	detailsTitle,
	infosDetails
}: PdfViewerDetailsContainerProps) {
	return (
		<div className="container-details-page">
			<nav className="details-nav">
				<div className="link-section">
					<Button
						styled="primary"
						kind="icon"
						cssClass="left-arrow"
						onClick={onClickReturn}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="details-nav-title">Detalhes</span>
				</div>

				<div className="button-section">{actionButtons}</div>
			</nav>

			<div className="details-content">
				<iframe
					title="Unique title for the iframe"
					src={pdfUrl || ""}
					loading="lazy"
				/>
				<div className="details-data">
					<div className="details-scroll">
						<div className="details-heading">
							<span>{detailsTitle}</span>
						</div>
						<div className="divider" />

						{infosDetails.map((detail) => (
							<>
								<p className="details-info-title">{detail.title}</p>
								<div className="details-info-wrapper">
									{detail.infos.map((info) => (
										<div
											className={`details-info-row ${
												info.alignClass ?? "start-align"
											}`}
										>
											<span
												className={`details-info-label ${
													info.value ? "w-1/2" : "whitespace-pre-wrap"
												}`}
											>
												{info.label}
											</span>
											{!info.value ? null : (
												<span
													className={`details-info-value ${
														typeof info.value === "string"
															? "font-semibold w-1/2"
															: ""
													}`}
												>
													{info.value}
												</span>
											)}
										</div>
									))}
								</div>
							</>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
