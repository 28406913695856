import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Loading } from "components";
import { PdfViewerDetailsContainer } from "features";

import { ErrorHelper, FileHelper } from "helpers";
import { Clause } from "models/covenant/Clause";
import { Monitoring } from "models/covenant/Monitoring";
import { ClauseService } from "services/clauses";
import { CovenantService } from "services/covenants";

import { IcoExport } from "assets/icons";
import colors from "colors";
import ToastContent from "components/ToastContent";
import { useHandlePdfUrl } from "hooks/useHandlePdfUrl";
import "./styles.scss";

export default function ClauseDetailsPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [loading, setLoading] = useState(false);
	const [clause, setClause] = useState<Clause>();
	const clauseService = new ClauseService();
	const covenantService = new CovenantService();

	const getClause = () => {
		setIsFetchingData(true);

		if (!id) {
			console.debug("Id inválido!");
			return;
		}

		clauseService
			.retrieve(id)
			.then((response: any) => {
				setClause(response);
				setIsFetchingData(false);
			})
			.catch((error: any) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	const exportCovenant = () => {
		setLoading(true);
		const covenant = clause?.covenant;

		if (!clause || !covenant || !covenant.covenantId) {
			return;
		}

		covenantService
			.exportCovenants([covenant.covenantId])
			.then((response) => {
				setLoading(false);

				toast.dark(
					<ToastContent
						type="success"
						title="Dowload concluído"
						subtitle={`	O contrato ${clause?.clauseNumber} foi baixado com sucesso`}
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				FileHelper.downloadExcelFile(
					response,
					`${covenant.exportFileName}.xlsx`
				);
			})
			.catch((error) => {
				setLoading(false);
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	useEffect(() => {
		getClause();
	}, []);

	const pdfUrl = useHandlePdfUrl(clause?.covenant?.contractPdf, (error) => {
		toast.error(
			<ToastContent
				type="error"
				title="Erro"
				subtitleError={error}
				onClose={() => toast.dismiss()}
			/>
		);
	});

	return isFetchingData ? (
		<Loading type="primary" />
	) : (
		<PdfViewerDetailsContainer
			onClickReturn={() => navigate("/tasks")}
			actionButtons={
				<Button
					kind="default"
					styled="primary"
					onClick={exportCovenant}
					cssClass={loading ? "edit-button details-button" : "edit-button"}
				>
					<IcoExport color={colors.neutral["high-pure-50"]} size="1.75rem" />
					Exportar contrato
				</Button>
			}
			pdfUrl={pdfUrl}
			detailsTitle="Informações da tarefa"
			infosDetails={[
				{
					title: "Cláusula",
					infos: [
						{
							label: "Número",
							value: clause?.clauseNumber
						},
						{
							label: "Categoria",
							value: clause?.clauseCategory.name
						},
						{
							label: "Periodicidade",
							value: clause?.frequency.name
						}
					]
				},
				{
					title: "Descrição da cláusula",
					infos: [{ label: clause?.description ?? "" }]
				},
				{
					title: "Contrato",
					infos: [
						{
							label: "Tipo de contrato",
							value: clause?.covenant.covenantType?.name
						},
						{
							label: "Número do contrato",
							value: clause?.covenant.covenantNumber ?? ""
						},
						{
							label: "Contratante",
							value: clause?.covenant.contractingCompaniesNames ?? ""
						},
						{
							label: "Tipo de contratante",
							value: clause?.covenant.contractingType?.name
						},
						{
							label: "Credor",
							value: clause?.covenant.creditor?.name
						},
						{
							label: "Tipo de credor",
							value: clause?.covenant.creditorType?.name
						},
						{
							label: "Início",
							value: clause?.covenant.signatureDate?.toLocaleDateString()
						},
						{
							label: "Vencimento",
							value: clause?.covenant.expirationDate?.toLocaleDateString()
						}
					]
				},
				{
					title: "Times vinculados",
					infos: clause?.teams.map((team: Monitoring) => ({
						label: team.name
					})) ?? [{ label: "Sem times associados" }]
				}
			]}
		/>
	);
}
