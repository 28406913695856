import { toast } from "react-toastify";

import { Button } from "components";
import Modal from "components/Modal";

import ToastContent from "components/ToastContent";
import "./styles.scss";
import { EnableModalProps } from "./types";

export default function EnableModal({
	itemForEnable,
	itemForService,
	confirMessage,
	modalTitle,
	modalInfo,
	isOpen,
	onClose,
	onConfirm
}: EnableModalProps) {
	const itemService = new itemForService();

	const confirmDeletion = () => {
		if (!itemForEnable) {
			return;
		}

		console.dir(itemForEnable);
		console.dir(itemService);

		itemService
			.enableUser(itemForEnable?.id)
			.then(() => {
				toast.dark(
					<ToastContent
						type="success"
						title="Acesso Habilitado"
						subtitle={confirMessage}
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				onConfirm();
			})
			.catch((error: unknown) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{ hideProgressBar: true }
				);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={modalTitle}
			cssClass="include-modal"
			kind="warning"
		>
			<span className="modal-info">{modalInfo}</span>
			<div className="button-section">
				<Button
					kind="default"
					styled="secondary"
					onClick={onClose}
					cssClass="cancel-button"
				>
					Cancelar
				</Button>
				<Button
					kind="default"
					styled="primary"
					type="reset"
					onClick={confirmDeletion}
					cssClass="confirm-button"
				>
					Reativar usuário
				</Button>
			</div>
		</Modal>
	);
}
