import { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import {
	Avatar,
	Button,
	Pagination,
	Table,
	DeletionModal as TeamDeletionModal
} from "components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import { IcoFile, IcoPencil, IcoTrash } from "assets/icons";
import colors from "colors";
import { Team } from "models/auth/Team";
import { User } from "models/auth/User";
import { TeamService } from "services/teams";
import "./styles.scss";

import { format } from "date-fns";
import { useQuerystringDetails } from "hooks/querystringFilter";
import { useSelector } from "react-redux";
import { RootState } from "store";
import TeamDetailsDrawer from "./DetailsDrawer";

type TeamTableProps = {
	content: any[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	setFilters: (value: any) => void;
};

const teamListLimit = 4;
let remainderAvatarCounter = 0;

function getUsersPresentation(users?: User[] | undefined) {
	if (!users || users.length === 0) {
		return "Nenhum usuário vinculado";
	}

	remainderAvatarCounter++;

	const remainder = users.length - teamListLimit;
	const remainderUsers: string[] = [];

	return (
		<div className="users-row">
			{users.map((user, idx) => {
				if (idx >= teamListLimit) {
					remainderUsers.push(user.name);
					return null;
				}

				const text = user.name.match(/[A-Z]/g)?.slice(0, 1).join("");
				return (
					<div>
						<Tooltip
							anchorSelect={`#avatar--${user.id}`}
							place="top"
							className="z-50"
						>
							{user.name}
						</Tooltip>
						<Avatar
							key={`user-${user.id}`}
							cssClass={`user-avatar--${idx}`}
							id={`avatar--${user.id}`}
							borderWidth={1}
						>
							{text}
						</Avatar>
					</div>
				);
			})}
			{remainder > 1 && (
				<div>
					<Tooltip
						anchorSelect={`#remainder-avatar--${remainderAvatarCounter}`}
						place="top"
						className="z-50"
					>
						{`${remainderUsers.join("; ")}.`}
					</Tooltip>
					<Avatar
						key="user-remainder"
						color="white"
						bgColor={colors.neutral["low-pure-500"]}
						borderStyle="none"
						cssClass={`user-avatar--${teamListLimit} user-avatar-remainder`}
						id={`remainder-avatar--${remainderAvatarCounter}`}
					>
						{`+${remainder}`}
					</Avatar>
				</div>
			)}
		</div>
	);
}

export default function TeamsTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting,
	setFilters
}: TeamTableProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [teamForDeletion, setTeamForDeletion] = useState<Team>();
	const {
		itemForInspection,
		getItemForInspectionFromUrl,
		setItemForInspectionInUrl
	} = useQuerystringDetails(content, "id", setFilters);
	const { isMaster } = useSelector((state: RootState) => state.auth);

	const navigate = useNavigate();

	const columns: ColumnDef<Team>[] = [
		{
			id: "name",
			accessorKey: "name",
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			header: () => <span>Time</span>,
			meta: {
				columnClass: "name-column"
			}
		},
		{
			accessorKey: "users",
			header: () => <span>Usuários</span>,
			enableSorting: false,
			cell: (row) => {
				return getUsersPresentation(row.getValue() as User[] | undefined);
			},
			meta: {
				columnClass: "users-column"
			}
		},
		{
			id: "createdOn",
			accessorKey: "createdOn",
			header: () => <span>Criado em</span>,
			cell: (row) => {
				return format(new Date(row.getValue() as string), "dd/MM/yyyy");
			},
			meta: {
				columnClass: "createdOn-column"
			}
		},
		{
			id: "action",
			header: "Ações",
			enableSorting: false,
			cell: ({ row }) => (
				<span>
					<Tooltip anchorSelect=".detail-button" place="top" className="z-50">
						Detalhes
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => setItemForInspectionInUrl(row.original)}
						cssClass="detail-button"
					>
						<IcoFile size="1.75rem" />
					</Button>
					{isMaster && (
						<>
							<Tooltip anchorSelect=".edit-button" place="top" className="z-50">
								Editar time
							</Tooltip>
							<Button
								kind="icon"
								styled="ghost"
								size="large"
								onClick={() => navigate(`/teams/edit/${row.original.id}`)}
								cssClass="edit-button"
							>
								<IcoPencil size="1.75rem" />
							</Button>
							<Tooltip
								anchorSelect=".deletion-button"
								place="top"
								className="z-50"
							>
								Excluir time
							</Tooltip>
							<Button
								kind="icon"
								styled="ghost"
								size="large"
								onClick={() => {
									setTeamForDeletion(row.original);
									setIsModalOpen(true);
								}}
								cssClass="deletion-button"
							>
								<IcoTrash size="1.75rem" />
							</Button>
						</>
					)}
				</span>
			),
			meta: {
				columnClass: "action-column"
			}
		}
	];

	const closeModal = () => {
		setTeamForDeletion(undefined);
		setIsModalOpen(false);
	};

	const confirmModalAction = () => {
		setTeamForDeletion(undefined);
		setIsModalOpen(false);
		onPageChange(currentPage, pageSize);
	};

	useEffect(() => getItemForInspectionFromUrl(), []);

	return (
		<>
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<TeamDetailsDrawer
				isOpen={!!itemForInspection}
				onClose={() => setItemForInspectionInUrl(undefined)}
				team={itemForInspection}
			/>
			<TeamDeletionModal
				itemForDeletion={teamForDeletion}
				itemForService={TeamService}
				confirMessage="O acesso ao time foi removido"
				modalTitle="Excluir time"
				modalInfo="Ao excluir, todas as informações serão deletadas permanentemente do sistema. Não haverá possibilidade de reverter esta ação."
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
			/>
		</>
	);
}
