import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IcoArrowDown } from "assets/icons";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { clearSearch } from "store/features/search/slice";

import Account from "./Account";
import { HeaderProps } from "./types";

import "./styles.scss";

export default function Header({ account }: HeaderProps) {
	const [toggleSideMenu, setToggleSideMenu] = useState<boolean>(false);
	const [toggleSubMenu, setToggleSubMenu] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { isMaster, isManager, isAnalyst } = useSelector(
		(state: RootState) => state.auth
	);

	const profile = account;

	const tabs = isMaster
		? [
				{
					name: "Escrituração",
					baseUrl: "",
					subMenu: [
						{
							name: "Contratos",
							baseUrl: "/covenants"
						},
						{
							name: "Cláusulas",
							baseUrl: "/clauses"
						},
						{
							name: "Categorias",
							baseUrl: "/categories"
						}
					]
				},
				{
					name: "Monitoramento",
					baseUrl: "/tasks",
					subMenu: []
				},
				{
					name: "Atribuições",
					baseUrl: "",
					subMenu: [
						{
							name: "Usuários",
							baseUrl: "/users"
						},
						{
							name: "Times",
							baseUrl: "/teams"
						}
					]
				}
		  ]
		: isAnalyst
		? [
				{
					name: "Escrituração",
					baseUrl: "",
					subMenu: [
						{
							name: "Contratos",
							baseUrl: "/covenants"
						},
						{
							name: "Categorias",
							baseUrl: "/categories"
						}
					]
				},
				{
					name: "Monitoramento",
					baseUrl: "/tasks",
					subMenu: []
				},
				{
					name: "Atribuições",
					baseUrl: "",
					subMenu: [
						{
							name: "Times",
							baseUrl: "/teams"
						}
					]
				}
		  ]
		: isManager
		? [
				{
					name: "Escrituração",
					baseUrl: "",
					subMenu: [
						{
							name: "Contratos",
							baseUrl: "/covenants"
						},
						{
							name: "Cláusulas",
							baseUrl: "/clauses"
						},
						{
							name: "Categorias",
							baseUrl: "/categories"
						}
					]
				},
				{
					name: "Monitoramento",
					baseUrl: "/tasks",
					subMenu: []
				},
				{
					name: "Atribuições",
					baseUrl: "",
					subMenu: [
						{
							name: "Usuários",
							baseUrl: "/users"
						},
						{
							name: "Times",
							baseUrl: "/teams"
						}
					]
				}
		  ]
		: [];

	useEffect(() => {
		const elementsWithSubMenu = document.querySelectorAll(".has-sub-menu");
		elementsWithSubMenu.forEach((element) => {
			element.classList.remove("active-sub-menu");
		});
		setToggleSubMenu(false);
	}, [navigate]);

	const handleToggleSideMenu = () => {
		if (!toggleSideMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
		setToggleSideMenu(!toggleSideMenu);
	};

	const handleToggleSubMenu = (e: HTMLElement) => {
		const activeSubMenu = document.querySelector(".active-sub-menu");
		const menu = document.querySelector(".tab-wrapper");

		const isClickInsideMenu = (target: HTMLElement) => {
			return menu?.contains(target);
		};
		const closeSubMenu = () => {
			if (activeSubMenu) {
				activeSubMenu.classList.remove("active-sub-menu");
			}
			e.classList.remove("active-sub-menu");

			setToggleSubMenu(false);
		};

		if (!isClickInsideMenu(e)) {
			closeSubMenu();
			return;
		}

		document.addEventListener("click", (event) => {
			if (!isClickInsideMenu(event.target as HTMLElement)) {
				closeSubMenu();
			}
		});

		if (activeSubMenu && activeSubMenu !== e) {
			activeSubMenu.classList.remove("active-sub-menu");
		}

		if (!e.classList.contains("active-sub-menu") || !toggleSubMenu) {
			e.classList.add("active-sub-menu");
		} else {
			e.classList.remove("active-sub-menu");
		}

		setToggleSubMenu(!toggleSubMenu);
	};

	const goTo = (path: string) => {
		const activeSubMenu = document.querySelector(".active-sub-menu");

		if (activeSubMenu) {
			activeSubMenu.classList.remove("active-sub-menu");
		}
		navigate(path);
		dispatch(clearSearch());
	};

	return (
		<header className="header">
			<div className="wrapper-general">
				<div className="logo-wrapper">Covenants</div>
				<nav className="tab-wrapper">
					{tabs.map((tab) => (
						<ul key={`tab-${tab.name}`}>
							<li
								key={`tab-${tab.name}`}
								role="presentation"
								onClick={(e) =>
									tab.subMenu.length > 0
										? handleToggleSubMenu(e.currentTarget)
										: goTo(tab.baseUrl)
								}
								className={
									tab.subMenu.length === 0
										? window.location.pathname.startsWith(tab.baseUrl)
											? "active-header-tab"
											: ""
										: "has-sub-menu"
								}
							>
								<span>{tab.name}</span>
								{tab.subMenu.length > 0 && <IcoArrowDown />}
								{tab.subMenu.length > 0 && (
									<div>
										<ul>
											{tab.subMenu.map((item) => (
												<li
													key={`tab-${item.name}`}
													role="presentation"
													onClick={() => goTo(item.baseUrl)}
													className={
														window.location.pathname.startsWith(item.baseUrl)
															? "active-tab"
															: ""
													}
												>
													<span>{item.name}</span>
												</li>
											))}
										</ul>
									</div>
								)}
							</li>
						</ul>
					))}
				</nav>
				{profile && (
					<div className="help-wrapper">
						<span className="user">
							<Account account={profile} onClose={handleToggleSideMenu} />
						</span>
					</div>
				)}
			</div>
		</header>
	);
}
