import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { IcoClose, IcoFilter, IcoSearch } from "assets/icons";
import colors from "colors";
import { Avatar, Button } from "components";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import { useQuerystringFilters } from "hooks/querystringFilter";
import { Team } from "models/auth/Team";
import { Category } from "models/covenant/Category";
import { ContractingCompany } from "models/covenant/ContractingCompany";
import { Covenant } from "models/covenant/Covenant";
import { CategoryService } from "services/categories";
import { ContractingCompanyService } from "services/companies";
import { CovenantService } from "services/covenants";
import { TeamService } from "services/teams";
import { ClauseFilter } from "../types";
import ClauseFilterDrawer from "./FilterDrawer";
import "./styles.scss";

export default function ClausesListFilters({
	onApply
}: {
	onApply: (filters: any) => void;
}) {
	const emptyFilters = {
		textFilter: "",
		fromDate: undefined,
		untilDate: undefined,
		clauses: [],
		categories: [],
		teams: [],
		contractingCompanies: [],
		frequencies: [],
		status: [],
		covenants: []
	};
	const [filters, setFilters] = useState<ClauseFilter>(emptyFilters);
	const [qtyFiltersApplied, setQtyFiltersApplied] = useState(0);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [contractingCompanies, setContractingCompanies] = useState<
		ContractingCompany[]
	>([]);
	const [covenants, setCovenants] = useState<Covenant[]>([]);
	const [categories, setCategories] = useState<Category[]>([]);
	const [teams, setTeams] = useState<Team[]>([]);
	const { getUrlFilters } = useQuerystringFilters(filters);
	const contractingCompanyService = new ContractingCompanyService();
	const covenantService = new CovenantService();
	const categoryService = new CategoryService();
	const teamService = new TeamService();
	const [isSearch, setIsSearch] = useState(false);

	const onApplyFilters = (appliedFilters: any) => {
		let qtyFilters = 0;
		if (appliedFilters.fromDate) {
			qtyFilters++;
		}
		if (appliedFilters.untilDate) {
			qtyFilters++;
		}
		if (appliedFilters.status?.length) {
			qtyFilters++;
		}
		if (appliedFilters.contractingCompanies?.length) {
			qtyFilters++;
		}
		if (appliedFilters.covenants?.length) {
			qtyFilters++;
		}
		if (appliedFilters.frequencies?.length) {
			qtyFilters++;
		}
		if (appliedFilters.categories?.length) {
			qtyFilters++;
		}
		if (appliedFilters.teams?.length) {
			qtyFilters++;
			if (typeof appliedFilters.teams === "object") {
				appliedFilters.teams = appliedFilters.teams.map((team: any) => {
					if (typeof team !== "number") {
						team = Number(team);
					}
					return team;
				});
			}
		}

		setQtyFiltersApplied(qtyFilters);
		setSearchText(appliedFilters.textFilter || "");

		setFilters({ ...appliedFilters });
		onApply({ ...appliedFilters });
		setIsDrawerOpen(false);
	};

	const getContractingCompanies = () => {
		contractingCompanyService
			.listContractingCompanies()
			.then((response) => setContractingCompanies(response))
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	const getCovenants = () => {
		covenantService
			.listCovenants({})
			.then((response) => setCovenants(response))
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	const getCategories = () => {
		categoryService
			.listCategories({})
			.then((response) => setCategories(response))
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	const getTeams = () => {
		teamService
			.list({})
			.then((response) => setTeams(response))
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	};

	useEffect(() => {
		getContractingCompanies();
		getCovenants();
		getCategories();
		getTeams();
		onApplyFilters(getUrlFilters());
	}, []);

	const onSearchInputApply = (event: React.SyntheticEvent) => {
		if (
			(event as React.KeyboardEvent).key === "Enter" ||
			(event as React.MouseEvent).type === "click"
		) {
			const newFilters = {
				...filters,
				textFilter: searchText
			};

			setFilters(newFilters);
			onApply(newFilters);
			if (searchText.length > 0) {
				setIsSearch(true);
			}
		}
	};

	const resetFilters = () => {
		setSearchText("");
		setIsSearch(false);
		setFilters(emptyFilters);
		setQtyFiltersApplied(0);
		onApply(emptyFilters);
		setIsDrawerOpen(false);
	};

	const resetSearch = () => {
		setSearchText("");
		setIsSearch(false);
		const newFilters = {
			...filters,
			textFilter: ""
		};
		onApply(newFilters);
	};
	return (
		<>
			<div className="clause-filter-wrapper">
				<div>
					{qtyFiltersApplied > 0 && (
						<Button
							kind="link"
							styled="ghost"
							onClick={resetFilters}
							cssClass="clear-filters-button"
						>
							Limpar filtros
						</Button>
					)}
					<Button
						kind="chip"
						styled={qtyFiltersApplied > 0 ? "primary" : "secondary"}
						onClick={() => setIsDrawerOpen(true)}
						cssClass="filter-button"
					>
						{qtyFiltersApplied === 0 && (
							<IcoFilter color={colors.neutral["low-pure-500"]} />
						)}
						Filtro
						{qtyFiltersApplied > 0 && (
							<Avatar size="sm" cssClass="filter-qty">
								{qtyFiltersApplied}
							</Avatar>
						)}
					</Button>
				</div>
				<div className="divider" />
				<div className="search-filter">
					<input
						placeholder="Pesquisa"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						onKeyDown={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					/>
					<div
						className="search-icon-wrapper"
						onClick={
							isSearch && searchText.length > 0
								? resetSearch
								: onSearchInputApply
						}
					>
						{isSearch ? (
							<IcoClose size="1rem" />
						) : (
							<IcoSearch color={colors.neutral["low-pure-500"]} size="1rem" />
						)}
					</div>
				</div>
			</div>
			<ClauseFilterDrawer
				fromDate={filters.fromDate}
				untilDate={filters.untilDate}
				status={filters.status}
				contractingCompanies={filters.contractingCompanies}
				covenants={filters.covenants}
				frequencies={filters.frequencies}
				teams={filters.teams}
				categories={filters.categories}
				onApplyFilters={onApplyFilters}
				isOpen={isDrawerOpen}
				onClickOutside={() => setIsDrawerOpen(false)}
				allContractingCompanies={contractingCompanies}
				allCovenants={covenants}
				allCategories={categories}
				allTeams={teams}
			/>
		</>
	);
}
