import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import colors from "colors";
import {
	Button,
	Container,
	EmptyList as EmptyUsersList,
	Loading
} from "components";
import { UserService } from "services/users";

import { SortingState } from "@tanstack/react-table";
import { IcoRefresh } from "assets/icons";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import TableHelper from "helpers/TableHelper";
import { useQuerystringParams } from "hooks/querystringFilter";
import { useHandleBack } from "hooks/useHandleBack";
import { useNavigate } from "react-router-dom";
import UsersListFilters from "./UsersListFilters";
import UsersTable from "./UsersTable";
import "./styles.scss";
import { UserFilter } from "./types";

export default function UserList() {
	const userService = new UserService();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [users, setUsers] = useState<any>([]);
	const [updatingUsers, setUpdatingUsers] = useState(false);
	const [filters, setFilters] = useState<UserFilter>({
		size: 10,
		page: 1,
		fromDate: undefined,
		untilDate: undefined,
		roles: [],
		teams: [],
		search: ""
	});

	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([
		{
			id: "name",
			desc: false
		}
	]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<UserFilter>(
		setFilters,
		sorting,
		setSorting
	);
	const navigate = useNavigate();

	const getUsers = () => {
		setIsFetchingData(true);
		const sortParam = TableHelper.sortingStateToParam(sorting);
		const params = {
			...filters,
			...sortParam
		};

		userService
			.listPaginated(params)
			.then((response) => {
				setUsers(response.content);
				setTotalPages(response.totalPages);
			})
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => {
				setIsFetchingData(false);
			});
	};

	useEffect(() => {
		if (initialized) {
			getUsers();
		} else {
			setInitialized(true);
		}
	}, [filters]);

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const updateUsersList = () => {
		setUpdatingUsers(true);
		userService
			.loadUsers()
			.then(() => {
				getUsers();
			})
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => setUpdatingUsers(false));
	};

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;
		const newFilters = {
			size: filters.size,
			page: 1,
			...appliedFilters,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};
		updateUrl(newFilters);
	};

	useHandleBack(navigate);

	return (
		<Container className="users-page">
			<div className="users-heading">
				<span className="users-title">Usuários</span>
				<UsersListFilters onApply={onApplyFilters} />
				<Button
					disabled={updatingUsers}
					kind="default"
					styled="secondary"
					cssClass={
						updatingUsers
							? "loading-update-users-button"
							: "update-users-button"
					}
					onClick={() => updateUsersList()}
				>
					<IcoRefresh color={colors.neutral["low-pure-500"]} size="2.5rem" />
					Atualizar Usuários
				</Button>
			</div>
			{isFetchingData ? (
				<Loading type="primary" />
			) : users.length === 0 ? (
				<EmptyUsersList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<UsersTable
					content={users}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
					setFilters={setFilters}
				/>
			)}
		</Container>
	);
}
