import { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import { IcoAddUser, IcoFile, IcoRemoveUser } from "assets/icons";
import colors from "colors";
import {
	Avatar,
	Button,
	Pagination,
	Table,
	DeletionModal as UserDeletionModal,
	EnableModal as UserEnableModal
} from "components";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import { Team } from "models/auth/Team";
import { User } from "models/auth/User";
import { UserService } from "services/users";
import "./styles.scss";

import { useQuerystringDetails } from "hooks/querystringFilter";
import { UserInnerProps } from "models/auth/types";
import { useSelector } from "react-redux";
import { RootState } from "store";
import UserDetailsDrawer from "./DetailsDrawer";

type UserTableProps = {
	content: any[];
	pageSize: number;
	currentPage: number;
	totalPages: number;
	onPageChange: (selectedPage: number, pageSize: number) => void;
	sorting?: SortingState;
	setSorting?: OnChangeFn<SortingState>;
	setFilters: (value: any) => void;
};

const userListLimit = 4;
let remainderAvatarCounter = 0;

function getRoles(props?: UserInnerProps | undefined) {
	return props?.rolesString.map((role: string, index: number) => (
		<p key={index}>
			{role}
			{index < props.rolesString.length - 1 && ","}
		</p>
	));
}

function getTeamsPresentation(teams?: Team[] | undefined) {
	if (!teams || teams.length === 0) {
		return "Nenhum time vinculado";
	}

	remainderAvatarCounter++;

	const remainder = teams.length - userListLimit;
	const remainderTeams: string[] = [];

	return (
		<div className="teams-row">
			{teams.map((team, idx) => {
				if (idx >= userListLimit) {
					remainderTeams.push(team.name);
					return null;
				}

				const text = team.name
					.match(/(?<!\p{L}\p{M}*)\p{L}\p{M}*/gu)
					?.slice(0, 1)
					.join("")
					.toUpperCase();
				return (
					<div key={`user-table-${idx}`}>
						<Tooltip
							anchorSelect={`#avatar--${team.id}`}
							place="top"
							className="z-50"
						>
							{team.name}
						</Tooltip>
						<Avatar
							key={`team-${team.id}`}
							cssClass={`team-avatar--${idx}`}
							id={`avatar--${team.id}`}
							borderWidth={1}
						>
							{text}
						</Avatar>
					</div>
				);
			})}
			{remainder >= 1 && (
				<div>
					<Tooltip
						anchorSelect={`#remainder-avatar--${remainderAvatarCounter}`}
						place="top"
						className="z-50"
					>
						{`${remainderTeams.join("; ")}.`}
					</Tooltip>
					<Avatar
						key="team-remainder"
						bgColor={colors.neutral["low-pure-500"]}
						color="white"
						borderStyle="none"
						cssClass={`team-avatar--${userListLimit} team-avatar-remainder`}
						id={`remainder-avatar--${remainderAvatarCounter}`}
					>
						{`+${remainder}`}
					</Avatar>
				</div>
			)}
		</div>
	);
}

export default function UsersTable({
	content,
	pageSize,
	currentPage,
	totalPages,
	onPageChange,
	sorting,
	setSorting,
	setFilters
}: UserTableProps) {
	const [isDisableModalOpen, setIsDisableModalOpen] = useState(false);
	const [isEnableModalOpen, setIsEnableModalOpen] = useState(false);
	const [userForDisable, setUserForDisable] = useState<User>();
	const [userForEnable, setUserForEnable] = useState<User>();
	const {
		itemForInspection,
		getItemForInspectionFromUrl,
		setItemForInspectionInUrl
	} = useQuerystringDetails(content, "id", setFilters);
	const { isMaster } = useSelector((state: RootState) => state.auth);

	const columns: ColumnDef<User>[] = [
		{
			accessorKey: "name",
			header: () => <span>Nome</span>,
			cell: (row) => {
				return (
					<div className="row-name">
						<p>{row.getValue() as string}</p>
					</div>
				);
			},
			meta: {
				columnClass: "name-column"
			}
		},
		{
			accessorKey: "props",
			enableSorting: false,
			header: () => <span>Perfil</span>,
			cell: (row) => {
				return getRoles(row.getValue() as UserInnerProps | undefined);
			},
			meta: {
				columnClass: "role-column"
			}
		},
		{
			id: "teams",
			accessorKey: "teams",
			header: () => <span>Time</span>,
			enableSorting: false,
			cell: (row) => {
				return getTeamsPresentation(row.getValue() as Team[] | undefined);
			},
			meta: {
				columnClass: "team-column"
			}
		},
		{
			accessorKey: "email",
			header: () => <span>Email</span>,
			meta: {
				columnClass: "email-column"
			}
		},
		{
			id: "enabledOn",
			accessorFn: (row) =>
				`${row.enabled ? "Ativo" : "Inativo"} desde ${new Date(
					row.enabledOn
				).toLocaleDateString()}`,
			header: () => <span>Status</span>,
			meta: {
				columnClass: "enabledOn-column"
			}
		},
		{
			id: "action",
			header: "Ações",
			enableSorting: false,
			cell: ({ row }) => (
				<span>
					{/* TODO: Fazer ação do botão -> redirecionar para tela de edição (deixado para issue de edição) */}
					<Tooltip anchorSelect=".detail-button" place="top" className="z-50">
						Detalhes
					</Tooltip>
					<Button
						kind="icon"
						styled="ghost"
						size="large"
						onClick={() => setItemForInspectionInUrl(row.original)}
						cssClass="detail-button"
					>
						<IcoFile size="1.75rem" />
					</Button>
					{isMaster && row.original.enabled ? (
						<>
							<Tooltip
								anchorSelect=".deactivate-button"
								place="top"
								className="z-50"
							>
								Desativar usuário
							</Tooltip>
							<Button
								kind="icon"
								styled="ghost"
								size="large"
								onClick={() => {
									setUserForDisable(row.original);
									setIsDisableModalOpen(true);
								}}
								cssClass="deactivate-button"
							>
								<IcoRemoveUser
									color={colors.feedback.negative[500]}
									size="1.75rem"
								/>
							</Button>
						</>
					) : (
						<>
							<Tooltip
								anchorSelect=".activate-button"
								place="top"
								className="z-50"
							>
								Ativar usuário
							</Tooltip>
							<Button
								kind="icon"
								styled="ghost"
								size="large"
								onClick={() => {
									setUserForEnable(row.original);
									setIsEnableModalOpen(true);
								}}
								cssClass="activate-button"
							>
								<IcoAddUser
									color={colors.feedback.positive[500]}
									size="1.75rem"
								/>
							</Button>
						</>
					)}
				</span>
			),
			meta: {
				columnClass: "action-column"
			}
		}
	];

	const closeDetailsDrawer = () => {
		setItemForInspectionInUrl(undefined);
	};

	const closeEnableModal = () => {
		setUserForEnable(undefined);
		setIsEnableModalOpen(false);
	};

	const closeDisableModal = () => {
		setUserForDisable(undefined);
		setIsDisableModalOpen(false);
	};

	const confirmEnableModalAction = () => {
		setUserForEnable(undefined);
		setIsEnableModalOpen(false);
		onPageChange(currentPage, pageSize);
	};

	const confirmDisableModalAction = () => {
		setUserForDisable(undefined);
		setIsDisableModalOpen(false);
		onPageChange(currentPage, pageSize);
	};

	useEffect(() => getItemForInspectionFromUrl(), []);

	return (
		<>
			<Table
				columns={columns}
				data={content}
				sorting={sorting}
				setSorting={setSorting}
			/>
			<Pagination
				totalPages={totalPages}
				currentPage={currentPage}
				onPageChange={onPageChange}
				pageSize={pageSize}
			/>
			<UserDetailsDrawer
				isOpen={!!itemForInspection}
				onClose={closeDetailsDrawer}
				user={itemForInspection || null}
			/>
			<UserDeletionModal
				itemForDeletion={userForDisable}
				itemForService={UserService}
				confirMessage="O acesso do usuário foi desativado"
				modalTitle="Deseja desativar este usuário?"
				modalInfo="O usuário perderá o acesso ao sistema, mas as informações dele serão salvas. Você poderá reativar o usuário a qualquer momento."
				isOpen={isDisableModalOpen}
				isDisableUserModal
				onClose={closeDisableModal}
				onConfirm={confirmDisableModalAction}
			/>
			<UserEnableModal
				itemForEnable={userForEnable}
				itemForService={UserService}
				confirMessage="O acesso do usuário foi reativado"
				modalTitle="Deseja reativar o usuário?"
				modalInfo="O usuário voltará a ter acesso ao sistema e todas as informações dele serão restauradas."
				isOpen={isEnableModalOpen}
				onClose={closeEnableModal}
				onConfirm={confirmEnableModalAction}
			/>
		</>
	);
}
