import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Loading, Multiselect } from "components";
import { ValueType } from "components/Multiselect/types";
import { ClauseBatchActionDrawer } from "features";

import { SelectHelper } from "helpers/SelectHelper";
import { ClauseService } from "services/clauses";
import { TeamService } from "services/teams";

import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import "./styles.scss";

type ChangeTeamDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	clausesIds: string[];
	reloadTable: () => void;
};

export default function ChangeTeamDrawer({
	isOpen,
	onClose,
	clausesIds,
	reloadTable
}: ChangeTeamDrawerProps) {
	const clauseService = new ClauseService();
	const teamService = new TeamService();
	const [teamsSelected, setTeamsSelected] = useState<string[]>([]);
	const [allTeamsOptions, setAllTeamsOptions] = useState<ValueType[]>([]);
	const [filteredTeamsOptions, setFilteredTeamsOptions] = useState<ValueType[]>(
		[]
	);
	const [isLoadingTeams, setIsLoadingTeams] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);

	const changeTeam = async () => {
		try {
			await clauseService.changeTeams({
				clausesIds,
				teamsIds: teamsSelected
			});

			onClose();
			reloadTable();
		} catch (err) {
			setHasErrors(true);
			throw err;
		}
	};

	useEffect(() => {
		setHasErrors(false);
	}, [isOpen]);

	useEffect(() => {
		setIsLoadingTeams(true);
		teamService
			.list({})
			.then((response) => {
				const allOptions = SelectHelper.toValueLabel(response, "id", "name");
				setAllTeamsOptions(allOptions);
				setFilteredTeamsOptions(allOptions);
				setIsLoadingTeams(false);
			})
			.catch((errorResponse) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={errorResponse}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			});
	}, []);

	const onTeamInputChange = (value: string) => {
		if (!value) {
			setFilteredTeamsOptions(allTeamsOptions);
		} else {
			const filtered: ValueType[] = [];
			allTeamsOptions.map((team) => {
				if (team.label.toLowerCase().includes(value.toLowerCase())) {
					filtered.push(team);
				}
				return team;
			});
			setFilteredTeamsOptions(filtered);
		}
	};

	return (
		<ClauseBatchActionDrawer
			isOpen={isOpen}
			onClose={onClose}
			clausesQty={clausesIds.length}
			value={teamsSelected}
			setValue={setTeamsSelected}
			title="Alteração de times"
			alertTitle="Alteração de times"
			alertMessage="Para realizar a troca de times, as cláusulas devem pertencer ao mesmo time e ao mesmo contratante, e também é necessário que estejam ativas."
			hasErrors={hasErrors}
			onConfirm={changeTeam}
			countTitle="Cláusulas selecionadas"
			modalTitle="Alterar time"
			modalInfo="Ao confirmar, todas as cláusulas selecionadas sofrerão alteração no time"
			toastSuccessTitle="Times alterados"
			toastSuccessMessage="Os times das cláusulas selecionadas foram alterados"
		>
			{!hasErrors && (
				<>
					<div className="title-team">Times vinculados</div>
					<div>
						{isLoadingTeams ? (
							<Loading />
						) : (
							<Multiselect
								label="Times"
								options={filteredTeamsOptions}
								onChange={(teams) =>
									setTeamsSelected((teams as any[]).map((team) => team.value))
								}
								value={allTeamsOptions.filter((team) =>
									teamsSelected?.includes(team.value)
								)}
								placeholder="Selecione times"
								onInputChange={onTeamInputChange}
							/>
						)}
					</div>
				</>
			)}
		</ClauseBatchActionDrawer>
	);
}
