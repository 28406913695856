import { toast } from "react-toastify";

import { IcoTrash } from "assets/icons";
import colors from "colors";
import { Button } from "components";
import Modal from "components/Modal";

import ToastContent from "components/ToastContent";
import "./styles.scss";
import { DeletionModalProps } from "./types";

export default function DeletionModal({
	itemForDeletion,
	itemForService,
	confirMessage,
	modalTitle,
	modalInfo,
	isOpen,
	isDisableUserModal = false,
	onClose,
	onConfirm
}: DeletionModalProps) {
	const itemService = new itemForService();

	const confirmDeletion = () => {
		if (!itemForDeletion) {
			return;
		}

		console.dir(itemForDeletion);
		console.dir(itemService);

		itemService
			.disable(itemForDeletion?.id)
			.then(() => {
				toast.dark(
					<ToastContent
						type="success"
						title="Acesso Removido"
						subtitle={confirMessage}
						onClose={() => toast.dismiss()}
					/>,
					{
						position: "top-center",
						autoClose: 3000,
						closeOnClick: false,
						progressClassName: "confirmation-toast-success-progress",
						className: "confirmation-toast",
						bodyClassName: "confirmation-toast-body"
					}
				);
				onConfirm();
			})
			.catch((error: unknown) => {
				toast.error(
					<ToastContent
						type="error"
						title="Erro"
						subtitleError={error}
						onClose={() => toast.dismiss()}
					/>,
					{ hideProgressBar: true }
				);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={modalTitle}
			cssClass="exclude-modal"
			kind={isDisableUserModal ? "warning" : "error"}
		>
			<span className="modal-info">{modalInfo}</span>
			<div className="button-section">
				<Button
					kind="default"
					styled="secondary"
					onClick={onClose}
					cssClass="cancel-button"
				>
					Cancelar
				</Button>
				<Button
					kind="default"
					styled="primary"
					type="reset"
					onClick={confirmDeletion}
					cssClass={`"confirm-button" ${
						!isDisableUserModal && "delete-button"
					}`}
				>
					{!isDisableUserModal && (
						<IcoTrash color={colors.neutral["high-pure-50"]} />
					)}
					{isDisableUserModal ? "Desativar usuário" : "Excluir"}
				</Button>
			</div>
		</Modal>
	);
}
