import colors from "colors";
import { IconProps } from "./types";

export default function IcoClip({
	color = colors.neutral["low-pure-500"],
	size = "1.5rem"
}: IconProps) {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14.5 5H17C17.5523 5 18 5.44772 18 6V19C18 19.5523 17.5523 20 17 20H7C6.44772 20 6 19.5523 6 19V6C6 5.44772 6.44772 5 7 5H9.5M9.5 4H14.5V7H9.5V4Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
