/* eslint-disable new-cap */
import { BaseModel } from "models/BaseModel";
import { ObjectWithAnyValues } from "utils/types";
import { get, getUpdatedUsers, patch, post, put, remove } from "./axios";
import { BasePageResponse } from "./types";

export class BaseServices<T extends BaseModel> {
	model: new (json: any) => T;

	constructor(model: any) {
		this.model = model;
	}

	async post(
		url: string,
		data: ObjectWithAnyValues,
		params: ObjectWithAnyValues = {}
	): Promise<any> {
		return post(url, data, { ...params });
	}

	async put(url: string, data: ObjectWithAnyValues): Promise<any> {
		return put(url, data);
	}

	async patch(url: string, data: ObjectWithAnyValues): Promise<any> {
		return patch(url, data);
	}

	async create(url: string, data: ObjectWithAnyValues): Promise<T> {
		return this.post(url, data).then((response) => new this.model(response));
	}

	async listPaginated(url: string, params: any): Promise<BasePageResponse> {
		const pageableParams = {
			...params,
			page: params.page - 1
		};
		const response = get(url, {
			params: pageableParams,

			paramsSerializer: {
				indexes: null
			}
		}).then((resp: BasePageResponse) => {
			resp.content = this.createResourcesFromListResponse(resp.content);
			return resp;
		});
		return response;
	}

	async list(url: string, params: any): Promise<T[]> {
		const response = get(url, params).then((resp) => {
			const resources = this.createResourcesFromListResponse(resp);
			return resources;
		});
		return response;
	}

	async get(url: string, params: any = {}): Promise<any> {
		return get(url, params);
	}

	async getUpdatedUsers(url: string): Promise<any> {
		return getUpdatedUsers(url);
	}

	async retrieve(url: string): Promise<T> {
		return get(url).then((data) =>
			BaseModel.createFromResponse(data, this.model)
		);
	}

	async delete(url: string, data: any = {}): Promise<any> {
		return remove(url, { data });
	}

	async enable(url: string, data: any = {}): Promise<any> {
		return post(url, { data });
	}

	createResourcesFromListResponse(results: any[]): T[] {
		return results.map((data) =>
			BaseModel.createFromResponse(data, this.model)
		);
	}
}
