import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { IcoArrowLeft } from "assets/icons";
import { Button, Input } from "components";
import ModalCancelConfirm from "components/ModalCancelConfirm";

import { Category } from "models/covenant/Category";
import { CategoryCreationProps } from "models/covenant/types";
import { CategoryService } from "services/categories";

import colors from "colors";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import { useSelector } from "react-redux";
import { RootState } from "store";
import "./styles.scss";

export default function CategoryCreateEditPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { importErrors } = useSelector((state: RootState) => state.covenant);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const categoryService = new CategoryService();
	const [initialValues, setInitialValues] = useState<CategoryCreationProps>(
		Category.initialValues
	);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [hasErrorsOnSubmit, setHasErrorsOnSubmit] = useState(false);

	const navigateBack = () => {
		navigate(importErrors ? "/covenants/create/error/details" : "/categories");
	};

	function createCategory(values: CategoryCreationProps) {
		setIsLoading(true);
		categoryService
			.createCategory(values)
			.then(() => {
				setIsLoading(false);
				navigateBack();
				if (!isCancelModalOpen) {
					toast.dark(
						<ToastContent
							type="success"
							title="Categoria criada"
							subtitle="Nova categoria foi adicionada"
							onClose={() => toast.dismiss()}
						/>,
						{
							position: "top-center",
							autoClose: 3000,
							closeOnClick: false,
							progressClassName: "confirmation-toast-success-progress",
							className: "confirmation-toast",
							bodyClassName: "confirmation-toast-body"
						}
					);
				}
			})
			.catch((error) => {
				setIsLoading(false);
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>,
						{ hideProgressBar: true }
					);
				}
			});
	}

	const formik = useFormik({
		initialValues,
		validationSchema: Category.validationSchema,
		onSubmit: (values) => createCategory(values)
	});

	const validateThenHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			setHasErrorsOnSubmit(true);
		} else {
			formik.submitForm();
		}
	};

	const closeModal = () => {
		setIsCancelModalOpen(false);
		navigateBack();
	};

	const confirmModalAction = () => {
		if (formik.isValid) {
			formik.submitForm();
			setIsCancelModalOpen(false);
			return true;
		}
		setIsCancelModalOpen(false);
		return false;
	};

	const hasChanges = () =>
		JSON.stringify(formik.values) !== JSON.stringify(initialValues);

	useEffect(() => {
		const name = searchParams.get("name");
		if (name === null) return;

		const newValues = { ...formik.values, name };
		setInitialValues({ ...newValues });
		formik.setValues(() => ({ ...newValues }));
	}, []);

	return (
		<form
			className="category-creation-page"
			onSubmit={(e) => e.preventDefault()}
		>
			<div className="category-creation-header">
				<div className="flex--row flex-items--center">
					<Button
						kind="icon"
						styled="ghost"
						cssClass="left-arrow"
						onClick={() =>
							hasChanges() ? setIsCancelModalOpen(true) : navigateBack()
						}
					>
						<IcoArrowLeft color={colors.neutral["low-pure-500"]} />
					</Button>
					<span className="category-creation-title">Criar categoria</span>
				</div>
			</div>
			<div className="category-creation-fields-group">
				<div className="category-creation-field-container">
					<Input
						label="Nome da categoria"
						placeholder="Digite"
						name="name"
						value={formik.values.name}
						onChange={(e) => {
							formik.handleChange(e);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								e.preventDefault();
							}
						}}
						error={hasErrorsOnSubmit ? formik.errors.name : undefined}
						className={`category-name-input ${
							hasErrorsOnSubmit && formik.errors.name ? "input-error" : ""
						}`}
					/>
				</div>
			</div>
			<div className="buttons">
				<Button
					cssClass="cancel-button"
					kind="default"
					styled="secondary"
					type="button"
					size="medium"
					onClick={() =>
						hasChanges() ? setIsCancelModalOpen(true) : navigateBack()
					}
				>
					Cancelar
				</Button>
				<Button
					cssClass="submit-button"
					kind="default"
					styled="primary"
					type="button"
					size="medium"
					onClick={(e: any) => validateThenHandleSubmit(e)}
					disabled={!formik.dirty}
					isLoading={isLoading}
				>
					Salvar
				</Button>
			</div>
			<ModalCancelConfirm
				modalTitle="Deseja sair sem salvar?"
				modalInfo="Ao sair sem salvar, suas ações serão perdidas"
				isOpen={isCancelModalOpen}
				onClose={closeModal}
				onConfirm={confirmModalAction}
				closeLabel="Sair sem concluir"
				confirmLabel="Salvar"
				toastSuccessTitle="Categoria criada"
				toastSuccessMessage="Nova categoria foi adicionada"
			/>
		</form>
	);
}
