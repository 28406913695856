import { User } from "models/auth/User";
import { BaseServices } from "./baseServices";
import { BasePageResponse } from "./types";

export class UserService extends BaseServices<User> {
	constructor() {
		super(User);
	}

	async listPaginated(params: any): Promise<BasePageResponse> {
		const pageableParams = {
			...params,
			roles: params.roles?.join(","),
			teams: params.teams?.join(","),
			enabled: true
		};
		return super.listPaginated("users", pageableParams);
	}

	async loadUsers() {
		return this.getUpdatedUsers("users/ad");
	}

	async disable(userId: string | number) {
		return this.delete(`users/deactivate/${userId}`);
	}

	async enableUser(userId: string | number) {
		return this.enable(`users/activate/${userId}`);
	}
}
