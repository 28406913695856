import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Login, OAuthError, Page404 } from "pages";

import { OAuth2RedirectHandler } from "components";
import { LoggedLayout } from "layouts";
import { useDispatch, useSelector } from "react-redux";
import { AuthService } from "services/auth";
import { RootState } from "store";
import { setLogout } from "store/features/auth/slice";
import AnalystRoutes from "./analyst";
import ManagerRoutes from "./manager";
import MasterRoutes from "./master";

// This path is sent in the POST so the backend can compose the redirect URL
export const RESET_PASSWORD_PATH = "resetar-senha";

function Routers() {
	const { pathname } = useLocation();

	const { user, isMaster, isManager, isAnalyst } = useSelector(
		(state: RootState) => state.auth
	);
	const dispatch = useDispatch();

	const authService = new AuthService();
	const bgClassName = "bg-neutral-high-100";

	useEffect(() => {
		if (
			pathname.includes("/edit") ||
			pathname.includes("/create") ||
			pathname.includes("/complete")
		) {
			document.body.classList.add(bgClassName);
		} else {
			document.body.classList.remove(bgClassName);
		}

		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
		}, 100);
		if (!sessionStorage.getItem(authService.USER_DATA)) {
			dispatch(setLogout());
		}
	}, [pathname]);

	return (
		<Routes>
			{user && (
				<Route path="/" element={<LoggedLayout />}>
					{isMaster && (
						<>
							<Route path="/" element={<Navigate to="/covenants" />} />
							<Route path="/*" element={<MasterRoutes />} />
						</>
					)}
					{isAnalyst && (
						<>
							<Route path="/" element={<Navigate to="/covenants" />} />
							<Route path="/*" element={<AnalystRoutes />} />
						</>
					)}
					{isManager && (
						<>
							<Route path="/" element={<Navigate to="/covenants" />} />
							<Route path="/*" element={<ManagerRoutes />} />
						</>
					)}
					<Route path="/*" element={<Page404 />} />
				</Route>
			)}
			<Route path="/*" element={<Login />} />
			<Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
			<Route path="/login/error" element={<OAuthError />} />
		</Routes>
	);
}

export default Routers;
