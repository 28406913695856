import { useEffect, useState } from "react";

export const useHandlePdfUrl = (
	byteFile?: string,
	onError?: (error: Error) => void
) => {
	const [pdfUrl, setPdfUrl] = useState<string | null>(null);

	useEffect(() => {
		if (byteFile) {
			try {
				const byteArray = new Uint8Array(
					atob(byteFile)
						.split("")
						.map((char) => char.charCodeAt(0))
				);
				const blob = new Blob([byteArray], { type: "application/pdf" });
				const url = URL.createObjectURL(blob);
				setPdfUrl(url);
			} catch (error: any) {
				onError?.(error);
			}
		}
	}, [byteFile]);

	return pdfUrl;
};
