import { AuthService } from "services/auth";
import CookieHelper from "./CookieHelper";

interface Errors {
	[key: string]: Array<FieldError>;
}

interface FieldError {
	code: string;
	message: string;
	details: any;
}

const ErrorHelper = {
	isAuthErrorDisplayed: false,

	parseErrorsObjectAsArray(errorsMap: Errors) {
		let parsedMessages: any[] = [];
		Object.keys(errorsMap).forEach((key) => {
			const errorItem: Array<FieldError> = errorsMap[key];
			const messages = errorItem.map((fieldError) => fieldError.message);
			parsedMessages = [...parsedMessages, ...messages];
		});
		return parsedMessages;
	},

	getObjMessageAsArray(obj: any) {
		if (typeof obj === "string") {
			return [obj];
		}
		if (!obj) {
			return [obj];
		}
		return Object.keys(obj).map((key) => {
			let message = obj[key];
			if (typeof message === "object") {
				message =
					message instanceof Array
						? message
								.map((msgItem) => this.getObjMessageAsArray(msgItem))
								.join(", ")
						: ErrorHelper.getObjMessageAsArray(message);
			}
			return `${key}: ${message}`;
		});
	},

	getErrorMessage(error: any) {
		const { response } = error;

		if (response?.status === 0) {
			return "Ocorreu um erro de conexão. Tente novamente.";
		}
		if (response?.status === 502) {
			return "Falha na conexão, tente novamente em alguns instantes";
		}
		if (response?.data) {
			if (typeof response.data === "string" && response.data !== "None") {
				return response.data;
			}
			const { data } = response;
			if (typeof data === "object") {
				if (!data.errors && data.message) {
					return data.message;
				}
				if (!data.message) {
					return "OPS! Alguma coisa deu errado. Tente novamente.";
				}
				try {
					const parsed = ErrorHelper.parseErrorsObjectAsArray(data.errors);
					return parsed?.join("\n");
				} catch (err) {
					const dataToParse = data?.detail || response.data;
					const parsed =
						dataToParse instanceof Array
							? dataToParse
							: ErrorHelper.getObjMessageAsArray(dataToParse);
					return parsed?.join("\n").replace("non_field_errors: ", "");
				}
			}
		}

		if (error?.response?.status === 401 && !ErrorHelper.isAuthErrorDisplayed) {
			ErrorHelper.isAuthErrorDisplayed = true;

			const authService = new AuthService();
			const token = CookieHelper.getCookies(authService.ACCESS_TOKEN);

			if (!token) {
				const user = sessionStorage.getItem(authService.USER_DATA);
				if (!user) {
					return Promise.reject(error);
				}

				sessionStorage.removeItem(authService.USER_DATA);
				authService.login();

				setTimeout(() => {
					ErrorHelper.isAuthErrorDisplayed = false;
				}, 3000);

				return "Sessão Expirada. Tentando refazer o login...";
			}

			return Promise.reject(error);
		}

		return "OPS! Alguma coisa deu errado. Tente novamente.";
	},

	errorMessages: {
		fieldRequired: "Campo obrigatório"
	}
};

export default ErrorHelper;
