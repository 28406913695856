import {
	CategoryList,
	ClauseDetailsPage,
	CovenantList,
	Page404,
	TaskList,
	TeamList
} from "pages";
import CompleteTaskPage from "pages/Tasks/Complete";
import { Route, Routes } from "react-router-dom";

export default function AnalystRoutes() {
	return (
		<Routes>
			<Route path="/covenants" element={<CovenantList />} />
			<Route path="/tasks" element={<TaskList />} />
			<Route path="/clauses/details/:id" element={<ClauseDetailsPage />} />
			<Route path="/tasks/complete/:id" element={<CompleteTaskPage />} />
			<Route path="/categories" element={<CategoryList />} />
			<Route path="/teams" element={<TeamList />} />
			<Route path="/*" element={<Page404 />} />
		</Routes>
	);
}
