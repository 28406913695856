import colors from "colors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IcoPlus } from "assets/icons";
import {
	Button,
	Container,
	EmptyList as EmptyClausesList,
	Loading
} from "components";
import TableHelper from "helpers/TableHelper";
import { ClauseService } from "services/clauses";

import { SortingState } from "@tanstack/react-table";
import ToastContent from "components/ToastContent";
import { ErrorHelper } from "helpers";
import { useQuerystringParams } from "hooks/querystringFilter";
import { useHandleBack } from "hooks/useHandleBack";
import ClausesListFilters from "./ClausesListFilters";
import ClausesTable from "./ClausesTable";
import { ClauseFilterTable } from "./types";

import "./styles.scss";

export default function ClauseList() {
	const navigate = useNavigate();
	const clauseService = new ClauseService();
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [clauses, setClauses] = useState<any>([]);
	const [filters, setFilters] = useState<ClauseFilterTable>({
		size: 10,
		page: 1
	});
	const [totalPages, setTotalPages] = useState(0);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [initialized, setInitialized] = useState(false);
	const { updateUrl } = useQuerystringParams<ClauseFilterTable>(
		setFilters,
		sorting,
		setSorting
	);

	const getClauses = () => {
		setIsFetchingData(true);

		const sortParam = TableHelper.sortingStateToParam(sorting, "id,desc");
		const params = {
			...filters,
			...sortParam
		};

		clauseService
			.listClausesPaginated(params)
			.then((response) => {
				setClauses(response.content);
				setTotalPages(response.totalPages);
			})
			.catch((error) => {
				if (!ErrorHelper.isAuthErrorDisplayed) {
					toast.error(
						<ToastContent
							type="error"
							title="Erro"
							subtitleError={error}
							onClose={() => toast.dismiss()}
						/>
					);
				}
			})
			.finally(() => {
				setIsFetchingData(false);
			});
	};

	useEffect(() => {
		if (initialized) {
			getClauses();
		} else {
			setInitialized(true);
		}
	}, [filters]);

	useEffect(() => {
		if (initialized) {
			const sortParam = TableHelper.sortingStateToParam(sorting);
			const newFilters = {
				...filters,
				...sortParam
			};
			updateUrl(newFilters);
		}
	}, [sorting]);

	const onPageChange = (selectedPage: number, pageSize: number) => {
		const newFilters = {
			...filters,
			size: pageSize,
			page: selectedPage
		};
		updateUrl(newFilters);
	};

	const onApplyFilters = (appliedFilters: any) => {
		const oldSortParam = TableHelper.sortingStateToParam(sorting);
		const newSortParam = appliedFilters.sort;

		const newFilters = {
			...appliedFilters,
			size: filters.size,
			page: 1,
			...(newSortParam ? { sort: newSortParam } : oldSortParam)
		};

		updateUrl(newFilters);
	};
	useHandleBack(navigate);

	return (
		<Container className="clauses-page">
			<div className="clauses-heading">
				<span className="clauses-title">Cláusulas</span>
				<ClausesListFilters onApply={onApplyFilters} />
				<Button
					kind="default"
					styled="primary"
					cssClass="create-clause-button"
					onClick={() => navigate("/clauses/create")}
				>
					<IcoPlus color={colors.neutral["high-pure-50"]} />
					<span>Criar cláusula</span>
				</Button>
			</div>
			{isFetchingData ? (
				<Loading type="primary" />
			) : clauses.length === 0 ? (
				<EmptyClausesList
					title="Nenhum resultado encontrado"
					message="Parece que não encontramos resultados correspondentes à sua pesquisa."
				/>
			) : (
				<ClausesTable
					content={clauses}
					currentPage={filters.page}
					pageSize={filters.size}
					totalPages={totalPages}
					onPageChange={onPageChange}
					sorting={sorting}
					setSorting={setSorting}
					reloadData={getClauses}
					setFilters={setFilters}
				/>
			)}
		</Container>
	);
}
